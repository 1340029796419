import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 mb-3" }
const _hoisted_3 = { class: "card pt-4 mb-xl-9" }
const _hoisted_4 = { class: "card-header border-0" }
const _hoisted_5 = { class: "card-title" }
const _hoisted_6 = { class: "fw-bolder" }
const _hoisted_7 = { class: "card-body pt-0" }
const _hoisted_8 = { class: "fw-bolder fs-2" }
const _hoisted_9 = { class: "fs-7 fw-normal text-muted" }
const _hoisted_10 = { class: "p-5 mt-5 mb-5 bg-primary bg-opacity-25 rounded" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col form-group" }
const _hoisted_13 = ["name", "value"]
const _hoisted_14 = { class: "col" }
const _hoisted_15 = { class: "col-4 mb-4" }
const _hoisted_16 = { class: "card bg-primary bg-opacity-25 h-100" }
const _hoisted_17 = { class: "card-header flex-nowrap border-0 pt-9" }
const _hoisted_18 = { class: "card-title m-0" }
const _hoisted_19 = {
  href: "#",
  class: "fs-4 fw-bold text-hover-primary text-gray-600 m-0"
}
const _hoisted_20 = { class: "card-body d-flex flex-column pb-8 flex-left" }
const _hoisted_21 = { class: "d-flex align-items-left fw-bold" }
const _hoisted_22 = ["href"]
const _hoisted_23 = {
  key: 1,
  target: "_blank",
  class: "btn bg-black text-white btn-sm btn-active-light-primary disabled"
}
const _hoisted_24 = {
  key: 0,
  class: "col-4 mb-4"
}
const _hoisted_25 = { class: "card bg-primary bg-opacity-25 h-100" }
const _hoisted_26 = { class: "card-header flex-nowrap border-0 pt-9" }
const _hoisted_27 = { class: "card-title m-0" }
const _hoisted_28 = {
  href: "#",
  class: "fs-4 fw-bold text-hover-primary text-gray-600 m-0"
}
const _hoisted_29 = { class: "card-body d-flex flex-column pb-8 flex-left" }
const _hoisted_30 = { class: "d-flex align-items-left fw-bold" }
const _hoisted_31 = ["href"]
const _hoisted_32 = {
  key: 1,
  target: "_blank",
  class: "btn bg-black text-white btn-sm btn-active-light-primary disabled"
}
const _hoisted_33 = {
  key: 0,
  class: "col-4 mb-4"
}
const _hoisted_34 = { class: "card bg-primary bg-opacity-25 h-100" }
const _hoisted_35 = { class: "card-body d-flex flex-column pb-8 flex-left" }
const _hoisted_36 = { class: "d-flex align-items-center fw-bold" }
const _hoisted_37 = ["href"]
const _hoisted_38 = { class: "col-12 mb-4" }
const _hoisted_39 = { class: "card h-100" }
const _hoisted_40 = { class: "card-header flex-nowrap border-0 pt-9" }
const _hoisted_41 = { class: "card-title m-0" }
const _hoisted_42 = {
  class: "fs-4 fw-bold text-hover-primary text-gray-600 m-0",
  href: "#"
}
const _hoisted_43 = { class: "card-body d-flex flex-column pb-8" }
const _hoisted_44 = { class: "custom-tree-node" }
const _hoisted_45 = ["href"]
const _hoisted_46 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tree = _resolveComponent("el-tree")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t("pages.attendanceMonitoring.title")), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("pages.attendanceMonitoring.desc")), 1)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCampaignId) = $event)),
                  class: "form-control form-control-solid"
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.campaigns, (campaign) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: campaign.id,
                      name: campaign.label,
                      value: campaign.id
                    }, _toDisplayString(campaign.label), 9, _hoisted_13))
                  }), 128))
                ], 512), [
                  [_vModelSelect, _ctx.selectedCampaignId]
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("button", {
                  type: "submit",
                  class: "btn btn-primary btn-light",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.reloadData()))
                }, _toDisplayString(_ctx.$t("general.search")), 1)
              ])
            ])
          ])
        ])
      ])
    ]),
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _cache[2] || (_cache[2] = _createElementVNode("i", { class: "fas fa-chart-line fs-3 me-3" }, null, -1)),
                  _createElementVNode("a", _hoisted_19, _toDisplayString(_ctx.$t("pages.attendanceMonitoring.groupparticipation")), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("div", _hoisted_21, [
                  (this.participationLink)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        href: this.participationLink,
                        target: "_blank",
                        class: "btn btn-primary btn-sm btn-light btn-active-light-primary"
                      }, _toDisplayString(_ctx.$t("pages.attendanceMonitoring.accessAttendance")), 9, _hoisted_22))
                    : (_openBlock(), _createElementBlock("a", _hoisted_23, _toDisplayString(_ctx.$t("pages.attendanceMonitoring.notavailable")), 1))
                ])
              ])
            ])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.$store.getters.currentUser.user.userprofile.related_companies, (company) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: company.id
            }, [
              (company.participationlink)
                ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                    _createElementVNode("div", _hoisted_25, [
                      _createElementVNode("div", _hoisted_26, [
                        _createElementVNode("div", _hoisted_27, [
                          _cache[3] || (_cache[3] = _createElementVNode("i", { class: "fas fa-chart-line fs-3 me-3" }, null, -1)),
                          _createElementVNode("a", _hoisted_28, _toDisplayString(company.name), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_29, [
                        _createElementVNode("div", _hoisted_30, [
                          (company.participationlink)
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 0,
                                href: company.participationlink,
                                target: "_blank",
                                class: "btn btn-primary btn-sm btn-light btn-active-light-primary"
                              }, _toDisplayString(_ctx.$t("pages.attendanceMonitoring.accessAttendance")), 9, _hoisted_31))
                            : (_openBlock(), _createElementBlock("a", _hoisted_32, " Non disponible "))
                        ])
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128)),
          (this.$store.getters.currentUser.user.userprofile.participationlink)
            ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                _createElementVNode("div", _hoisted_34, [
                  _cache[4] || (_cache[4] = _createElementVNode("div", { class: "card-header flex-nowrap border-0 pt-9" }, [
                    _createElementVNode("div", { class: "card-title m-0" }, [
                      _createElementVNode("i", { class: "fas fa-chart-line fs-3 me-3" }),
                      _createElementVNode("a", {
                        href: "#",
                        class: "fs-4 fw-bold text-hover-primary text-gray-600 m-0"
                      }, "Suivi personnalisé")
                    ])
                  ], -1)),
                  _createElementVNode("div", _hoisted_35, [
                    _createElementVNode("div", _hoisted_36, [
                      _createElementVNode("a", {
                        href: this.$store.getters.currentUser.user.userprofile.participationlink,
                        class: "btn btn-primary btn-sm btn-light btn-active-light-primary"
                      }, _toDisplayString(_ctx.$t("pages.attendanceMonitoring.accessAttendance")), 9, _hoisted_37)
                    ])
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_38, [
            _createElementVNode("div", _hoisted_39, [
              _createElementVNode("div", _hoisted_40, [
                _createElementVNode("div", _hoisted_41, [
                  _cache[5] || (_cache[5] = _createElementVNode("i", { class: "fas fa-chart-line fs-3 me-3" }, null, -1)),
                  _createElementVNode("a", _hoisted_42, _toDisplayString(_ctx.$t("pages.attendanceMonitoring.entitymonitoring")), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_43, [
                _createVNode(_component_el_tree, {
                  data: _ctx.entities,
                  "default-expanded-keys": _ctx.defaultExpandedKeys,
                  "node-key": "id",
                  ref: "tree",
                  "highlight-current": "",
                  props: _ctx.defaultProps,
                  onNodeExpand: _ctx.handleNodeClick,
                  onNodeCollapse: _ctx.handleNodeClick
                }, {
                  default: _withCtx(({ node, data }) => [
                    _createElementVNode("span", _hoisted_44, [
                      _createElementVNode("span", null, _toDisplayString(node.label), 1),
                      _createElementVNode("span", null, [
                        (data.externalcompanyparticipations.length > 0)
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 0,
                              href: data.externalcompanyparticipations[0].link,
                              target: "_blank"
                            }, _toDisplayString(_ctx.$t("pages.attendanceMonitoring.accessAttendance")), 9, _hoisted_45))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              _createTextVNode(_toDisplayString(_ctx.$t("pages.attendanceMonitoring.notavailable")), 1)
                            ], 64))
                      ])
                    ])
                  ]),
                  _: 1
                }, 8, ["data", "default-expanded-keys", "props", "onNodeExpand", "onNodeCollapse"])
              ])
            ])
          ])
        ], 64))
      : (_openBlock(), _createElementBlock("div", _hoisted_46, _cache[6] || (_cache[6] = [
          _createElementVNode("div", { class: "d-flex row-auto flex-center w-100 h-200px" }, [
            _createElementVNode("span", {
              class: "spinner-border text-primary",
              role: "status"
            })
          ], -1)
        ])))
  ]))
}